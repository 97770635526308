<template>
  <el-tooltip :content="value" :disabled="tooltipShow" placement="top-start">
    <span
      style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:200px;"
    >
      <span ref="content" @mouseover="isShowTooltip">{{ value }}</span>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  name: 'DescriptionsTooltip',
  props: ['value'],
  data() {
    return {
      tooltipShow: true
    }
  },
  methods: {
    isShowTooltip() {
      const bool = Number(this.$refs.content.offsetWidth) < Number(this.$refs.content.parentNode.offsetWidth)
      this.tooltipShow = bool
    }
  }
}
</script>

<style scoped></style>
